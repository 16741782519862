@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Roboto:wght@400;700&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto', sans-serif;
  background-color: #131620;
  color: white;
}

h1, h2, .rap-button {
  font-family: 'Bungee', cursive;
  @apply text-gray-800;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.rap-container {
  @apply max-w-full mx-auto p-4;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  margin-top: 1rem;
  position: relative;
  z-index: 1;
}

.rap-button {
  @apply font-bold py-2 px-4 rounded-full shadow-md transform transition;
  width: 100%;
  background: linear-gradient(45deg, #E242F9 0%, #4F93F0 100%);
  color: white;
  border: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.rap-button:hover {
  background: linear-gradient(45deg, #4F93F0 0%, #E242F9 100%);
  transform: scale(1.05);
}

.rap-button:disabled {
  background: linear-gradient(45deg, rgba(226, 66, 249, 0.5) 0%, rgba(79, 147, 240, 0.5) 100%);
  cursor: not-allowed;
  transform: none;
}

.rap-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  transition: 0.5s;
  opacity: 0;
}

.rap-button:hover::before {
  opacity: 1;
}

.rap-input {
  @apply rounded-full py-2 px-4 focus:outline-none;
  background-color: #1a1f2d;
  border: 2px solid #E242F9;
  color: white;
  width: 100%;
}

.rap-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.rap-card {
  background-color: #1a1f2d;
  @apply rounded-lg shadow-md p-4 mb-4 border border-gray-700;
  width: 100%;
}

.rap-lyrics {
  @apply whitespace-pre-wrap text-lg;
  color: #ffffff;
}

.rap-fragment-container {
  position: relative;
  padding-right: 3rem;
  width: 100%;
  background-color: rgba(31, 41, 55, 0.9);
  backdrop-filter: blur(4px);
}

.rap-fragment-content {
  width: 100%;
}

.rap-fragment-buttons {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
}

.rap-fragment-buttons .cursor-move,
.rap-fragment-buttons button {
  @apply text-purple-600 transition-colors duration-200 text-xl p-2;
  background-color: rgba(128, 0, 128, 0.1);
  border-radius: 50%;
}

.rap-fragment-buttons .cursor-move:hover,
.rap-fragment-buttons button:hover {
  background-color: rgba(128, 0, 128, 0.2);
}

.rap-left-column {
  @apply w-1/2 p-4 overflow-y-auto;
}

.rap-right-column {
  @apply w-1/2 p-4 overflow-y-auto;
}

h1.text-gray-800 {
  @apply text-4xl mb-8 font-bold text-center w-full;
  letter-spacing: 2px;
  color: white;
  text-shadow: 
    1px 1px 2px rgba(226, 66, 249, 0.8), /* Ombre violette avec transparence */
    2px 2px 4px rgba(79, 147, 240, 0.8);
  background: linear-gradient(45deg, #E242F9, #4F93F0);
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
}

h2.text-gray-800 {
  @apply text-3xl mb-4 font-bold text-center w-full;
  letter-spacing: 1px;
  color: white;
  text-shadow: 
    1px 1px 2px rgba(226, 66, 249, 0.8), /* Ombre violette avec transparence */
    2px 2px 4px rgba(79, 147, 240, 0.8);
  background: linear-gradient(45deg, #E242F9, #4F93F0);
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
}

.rap-button img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
  filter: brightness(0) invert(1); /* Pour que l'icône soit blanche */
}

.song-builder {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.song-builder-content {
  flex-grow: 1;
  overflow-y: auto;
}

.song-builder-content > div {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(226, 66, 249, 0.1);
  border: 2px solid rgba(226, 66, 249, 0.2);
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.song-builder-content > div:hover {
  background-color: rgba(233, 213, 255, 0.7); /* Fond légèrement plus opaque au survol */
}

.song-builder-content h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #7e22ce;
  text-align: left;
  padding-left: 0.5rem;
}

.song-builder-content > div > div {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(216, 180, 254, 0.5);
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
}

.song-builder-content > div > div:hover {
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.song-builder-content .cursor-move {
  color: #9333ea;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.song-builder-content .cursor-move:hover {
  opacity: 1;
}

.song-builder-content button {
  opacity: 0.7;
  transition: opacity 0.2s ease, color 0.2s ease;
}

.song-builder-content button:hover {
  opacity: 1;
}

.song-builder-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0;
  color: #4a5568; /* Couleur de texte plus douce */
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #1a1f2d;
}

nav h1 {
  font-family: 'Bungee', cursive;
  letter-spacing: 1px;
}

nav button {
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.font-bungee {
  font-family: 'Bungee', cursive;
}

/* Animation subtile pour le logo */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.login-logo {
  animation: float 6s ease-in-out infinite;
}

nav .nav-home,
nav .nav-texts {
  font-family: 'Bungee', cursive;
  font-weight: bold;
  color: white; /* Assurez-vous que la couleur du texte est appropriée */
}

nav a,
nav button {
  font-family: 'Bungee', cursive;
  font-weight: bold;
  color: white;
  text-decoration: none;
  margin: 0 10px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

/* Nouveaux styles pour transparence des fragments */
.rap-fragment {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(216, 180, 254, 0.3);
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  min-height: 3rem;
  position: relative;
  padding-right: 4rem;
}

.rap-fragment:hover {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Styles pour le bouton de suppression */
.supprimer-fragment {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  color: #e53e3e;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s ease, transform 0.2s ease;
  padding: 0.25rem;
  opacity: 0.6;
}

.supprimer-fragment:hover {
  color: #c53030;
  transform: scale(1.1);
  opacity: 1;
}

/* Quand il y a un bouton de déplacement */
.rap-fragment-buttons .supprimer-fragment {
  position: static;
}

/* Assurez-vous que les styles des boutons ne surchargent pas les classes personnalisées */
.rap-button {
  /* Vos styles existants pour rap-button */
}

/* Supprimer le style par défaut des boutons dans SongBuilder si nécessaire */
.song-builder-content button {
  /* Vos styles spécifiques si besoin */
}

/* Modifier la classe main-title-gradient pour qu'elle soit identique au style de RapGPT */
.main-title-gradient {
  @apply text-2xl font-bold text-center w-full mb-4;
  font-family: 'Bungee', cursive;
  letter-spacing: 1px;
  background: linear-gradient(45deg, #E242F9, #4F93F0);;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

/* Style pour les titres des sections (Couplet 1, Refrain 1, etc.) */
.song-builder-content h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #7e22ce;
  text-align: left;
  padding-left: 0.5rem;
}

/* Si vous utilisez la classe main-title-gradient pour ces titres, créez une variante */
.section-title {
  @apply text-xl font-bold mb-2;
  font-family: 'Bungee', cursive;
  background: linear-gradient(45deg, #9333EA, #3B82F6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  text-align: left;
}

.saved-lyrics textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #7e22ce;
  border-radius: 0.375rem;
  background-color: #2d3748;
  color: white;
  resize: vertical;
}

.saved-lyrics pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #f7fafc;
  background-color: #1a202c;
  padding: 1rem;
  border-radius: 0.375rem;
  overflow: auto;
}

.saved-lyrics .rap-button {
  margin-top: 0.5rem;
}

.supprimer-fragment {
  background: transparent;
  border: none;
  color: #e53e3e;
  font-size: 1.25rem;
  cursor: pointer;
  transition: color 0.2s ease, transform 0.2s ease;
  padding: 0.25rem;
}

.supprimer-fragment:hover {
  color: #c53030;
  transform: scale(1.2);
}

.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 1000;
}

.toast-success {
  background-color: #38a169; /* Vert */
}

.toast-error {
  background-color: #e53e3e; /* Rouge */
}

.toast button {
  background: none;
  border: none;
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
}

.rap-button.bg-purple-600 {
  background-color: #9333EA;
}

.rap-button.bg-purple-600:hover {
  background-color: #7E22CE;
}

.rap-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.saved-lyrics {
  position: relative;
}

.suggestion-overlay {
  position: absolute;
  left: 0.5rem;
  pointer-events: none;
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(156, 163, 175, 0.5);
  padding: 0.5rem;
  top: 0;
}

.suggestion-text {
  color: rgba(156, 163, 175, 0.5);
  pointer-events: none;
}

.textarea-container {
  position: relative;
  width: 100%;
}

.suggestion-text {
  color: rgba(156, 163, 175, 0.5);
  pointer-events: none;
}

.saved-lyrics textarea {
  font-family: monospace;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #7e22ce;
  border-radius: 0.375rem;
  background-color: transparent;
  color: white;
  resize: vertical;
}

.textarea-wrapper {
  position: relative;
  width: 100%;
  overflow: visible;
}

.suggestion-text {
  position: absolute;
  pointer-events: none;
  font-family: monospace;
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(156, 163, 175, 0.5);
  z-index: 10;
  white-space: pre;
}

.saved-lyrics textarea {
  font-family: monospace;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #7e22ce;
  border-radius: 0.375rem;
  background-color: #2d3748;
  color: white;
  resize: vertical;
}

/* Styles spécifiques pour SavedLyrics dans MesTextes */
.saved-lyrics.in-mes-textes {
  margin-bottom: 2rem;
}

.saved-lyrics.in-mes-textes .expanded-textarea {
  min-height: 400px; /* Hauteur minimale plus grande */
  transition: min-height 0.3s ease;
}

.saved-lyrics.in-mes-textes .textarea-wrapper {
  max-height: 80vh; /* Empêche le textarea de devenir trop grand */
}

/* Amélioration de l'apparence en mode édition */
.saved-lyrics.in-mes-textes textarea {
  background-color: rgba(45, 55, 72, 0.95);
  backdrop-filter: blur(4px);
  border: 1px solid #7e22ce;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.saved-lyrics.in-mes-textes textarea:focus {
  border-color: #9f7aea;
  box-shadow: 0 0 0 3px rgba(159, 122, 234, 0.3);
  outline: none;
}

.rewrite-popup {
  background-color: rgba(17, 24, 39, 0.95);
  border: 1px solid #7e22ce;
  border-radius: 0.375rem;
  padding: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 50;
  transition: all 0.2s ease;
}

.rewrite-popup button {
  white-space: nowrap;
  font-size: 0.875rem;
}